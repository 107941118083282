import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getBrowserLocale, treatLocale } from '@profile-ui-mono/utilities/helpers';
import { GET_PERSONAL_INFO } from '@profile-ui-mono/utilities/queries';
import { UserContext, LocaleProvider } from '@profile-ui-mono/utilities/context';
import { FLAG_NAMES } from '@profile-ui-mono/utilities/constants';
import { sendCustomLog } from '@profile-ui/shared-components';

const DataContext = React.createContext();

const DataProvider = ({ children, skeletons = null }) => {
  const userId = useContext(UserContext);
  const flags = useFlags();
  const { defaultLng, defaultCountry } = getBrowserLocale();
  let locale = null;

  const { loading, error, data } = useQuery(GET_PERSONAL_INFO, {
    variables: {
      filter: { id: userId },
    },
  });

  if (error) {
    const msgPrefix = 'Userprofile service';
    const message =
      error && error.message !== 'undefined'
        ? `${msgPrefix} : ${error.message}`
        : `${msgPrefix} : Unknown error`; // TODO : seems caused by the auth error from core service, needs discussion to modify the useQuery Error policy

    throw new Error(message);
  }

  if (!loading && data) {
    const { personalInfo } = data?.userProfile;

    if (!personalInfo.language) {
      personalInfo.language = defaultLng;
      sendCustomLog('DataProvider', `App set with default language, ${defaultLng}`);
    }
    locale = treatLocale(personalInfo.language);

    // RU locale is disabled as per business ask. Should default to En
    if (flags[FLAG_NAMES.RU_LOCALE_DISABLED] && locale === 'ru') {
      locale = 'en';
      sendCustomLog('DataProvider', `RU locale not accepted yet, reset lang back to En`);
    }

    if (!personalInfo.countryCode) {
      personalInfo.countryCode = defaultCountry;
      sendCustomLog(
        'DataProvider',
        `App set with default country code, ${defaultCountry}`
      );
    }
  }

  return loading && !locale ? (
    skeletons
  ) : (
    <DataContext.Provider value={{ profileData: data }}>
      <LocaleProvider lang={locale} skeletons={skeletons}>
        {children}
      </LocaleProvider>
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
