/**
 * Web SDK component attributes require to use string as value
 * Role value is Object, Industry value is Array
 * We need transform Role and Industry value to string 
 */

type Role = {
  roleId: string,
  roleName: string
}

export const processRoleValue = (value: Role) => {
  return {
    id: 'mfe_field-role',
    name: 'mfe_field-role',
    type: 'field',
    attributes: {
      fieldName: 'role',
      roleId: value.roleId,
      roleName: value.roleName
    }
  }
}

export const processIndustryValue = (value: Object[]) => {
  return {
    id: 'mfe_field-industry',
    name: 'mfe_field-industry',
    type: 'field',
    attributes: {
      fieldName: 'industry',
      industryName1: value[0] || '', // ex. "Architecture"
      industryName2: value[1] || '',
      industryName3: value[2] || '',
    }
  }
}

export const processCompanyValue = (value: string) => {
  return {
    id: 'mfe_field-company',
    name: 'mfe_field-company',
    type: 'field',
    attributes: {
      fieldName: 'company',
      fieldValue: value || '',
    }
  }
} 
