import { gql } from '@apollo/client';

const UPDATE_USER_ABOUTME = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfileV2(input: $input) {
      personalInfo {
        aboutMe
      }
    }
  }
`;

export default UPDATE_USER_ABOUTME;
