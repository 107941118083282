/* istanbul ignore file */
import { gql } from '@apollo/client';

const GET_LOCALE_BASED_OPTIONS = gql`
  query GetLocaleBasedOptions($rolesFilter: RolesInput!, $subSegmentsFilter: SubSegmentsInput!) {
    roles(filter: $rolesFilter) {
      roles {
        roleId
        roleName
      }
    }
    subSegments(filter: $subSegmentsFilter) {
      subSegments {
        subSegmentId
        subSegmentName
      }
    }
  }
`;

export default GET_LOCALE_BASED_OPTIONS;
