const DEFAULT_ISO_LOCALE = 'en_US';

const I18nConfigs = {
  lng: DEFAULT_ISO_LOCALE,
  fallbackLng: DEFAULT_ISO_LOCALE,
  defaultNS: '',
  debug: false,
  resources: {},
  interpolation: {
    escapeValue: false,
  },
  transSupportBasicHtmlNodes: true,
};

export default I18nConfigs;
