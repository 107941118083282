/* istanbul ignore file */
import { gql } from '@apollo/client';

const GET_PERSONAL_INFO = gql`
  query GetUserProfile($filter: UserProfileInput!) {
    userProfile(filter: $filter) {
      profileImages {
        isImagePresent
        thumbnails {
          sizeX120
        }
      }
      personalInfo {
        aboutMe
        primaryEmail
        language
        countryCode
        ldapInfo {
          isLdapEnabled
        }
        basicInfo {
          firstName
          lastName
          screenName
        }
        webLinks {
          linkedInProfile
          blog
          website
        }
        contactInfo {
          phones {
            category
            countryCode
            number
            extension
          }
          address {
            addressLine1
            addressLine2
            city
            stateProvince
            postalCode
            country
          }
          alternateEmail
        }
        professionalInfo {
          jobTitle
          industry
          industryCode
          company {
            companyName
          }
          additionalProfessionalInfo {
            roleId
            industryTaxonomyIds {
              industryGroupId
              segmentId
              subSegmentId
            }
          }
        }
      }
    }
  }
`;

export default GET_PERSONAL_INFO;
