const transformAEMData = (aemData) => {
  const transformedData = {};
  const aemEntities = aemData?.content?.entities;

  if (Array.isArray(aemEntities) && aemEntities.length) {
    aemEntities.forEach((item) => {
      const title = item?.properties?.title;
      const elements = item?.properties?.elements;

      transformedData[title] = {};

      for (const key in elements) {
        transformedData[title][key] = elements[key]?.value;
      }
    });
  }

  return transformedData;
};

export default transformAEMData;
