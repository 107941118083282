// Events type explanation
// https://wiki.autodesk.com/display/ADP/ADP+Usage+Analytics+core+schema+and+facets#ADPUsageAnalyticscoreschemaandfacets-Facets
export const adpAnalyticsEvents = {
  USER_VIEW: 'USER_VIEW',
  APPLICATION_VIEW: 'APPLICATION_VIEW',
  OTHER_VIEW: 'OTHER_VIEW',
  CLICK_COMPONENT: 'CLICK_COMPONENT',

  CLICK_PAGE: 'CLICK_PAGE',
  CLICK_DISPLAY: 'CLICK_DISPLAY',
  CLICK_OPERATION: 'CLICK_OPERATION',

  SESSION_START: 'SESSION_START',
  SESSION_END: 'SESSION_END',
  SESSION_DETAILS: 'SESSION_DETAILS',
  SESSION_FULL: 'SESSION_FULL',
  SESSION_PAGE: 'SESSION_PAGE',
  
  IDENTIFY_USER: 'IDENTIFY_USER',
  
  PROJECT_START: 'PROJECT_START',
  PROJECT_END: 'PROJECT_END',
  PROJECT_FULL: 'PROJECT_FULL',
  PROJECT_NAME: 'PROJECT_NAME',
  
  METRICS_COMPONENT: 'METRICS_COMPONENT',
  METRICS_SESSION: 'METRICS_SESSION',
  METRICS_SETTING: 'METRICS_SETTING',
  
  GESTURE_COMPONENT: 'GESTURE_COMPONENT',
  HOVER_COMPONENT: 'HOVER_COMPONENT',
  
  BACKGROUND_CALL: 'BACKGROUND_CALL',
  BACKGROUND_UPDATE: 'BACKGROUND_UPDATE',
  BACKGROUND_DOWNLOAD: 'BACKGROUND_DOWNLOAD',
  BACKGROUND_INSTALL: 'BACKGROUND_INSTALL',
  BACKGROUND_JOB: 'BACKGROUND_JOB',

  DOCUMENT_START: 'DOCUMENT_START',
  DOCUMENT_END: 'DOCUMENT_END',
  DOCUMENT_FULL: 'DOCUMENT_FULL',
  DOCUMENT_NAME: 'DOCUMENT_NAME',
  DOCUMENT_TYPE: 'DOCUMENT_TYPE',
}
