import React from 'react';
import Button from '@digital-hig/button';

type FieldProps = {
  handleSave: Function;
  handleCancel: Function;
  saveText: string;
  cancelText: string;
  continueText: string;
  isSuccess: boolean;
  isReadyToSave?: boolean;
  cancelBtnStyle?: object;
  successBtnStyle?: object;
};

const Footer = ({
  handleSave = () => {},
  handleCancel = () => {},
  saveText = 'Save',
  cancelText = 'Close',
  continueText = 'Continue',
  isSuccess = false,
  isReadyToSave = false,
  cancelBtnStyle,
  successBtnStyle,
}: FieldProps): JSX.Element =>
  !isSuccess ? (
    <>
      <Button
        id="mfe_cancel-button"
        data-testid="mfe_cancel-button"
        onClick={handleCancel}
        size="defaultSize"
        variant="text"
        style={cancelBtnStyle}
      >
        {cancelText}
      </Button>
      <Button
        id="mfe_save-button"
        data-testid="mfe_save-button"
        onClick={handleSave}
        size="defaultSize"
        variant="contained"
        disabled={!isReadyToSave}
        style={successBtnStyle}
      >
        {saveText}
      </Button>
    </>
  ) : (
    <Button
      id="mfe_continue-button"
      data-testid="mfe_continue-button"
      data-adp-click="true"
      onClick={handleCancel}
      size="defaultSize"
      variant="contained"
      style={successBtnStyle}
    >
      {continueText}
    </Button>
  );

export default Footer;
