export const getSelectedRole = (arr, selectedId, selectedName) => {
  let result;
  if (selectedId) {
    result = arr.find((item) => selectedId === item.roleId);
  } else if (selectedName) {
    result = arr.find((item) => selectedName === item.roleName);
  }

  return result ? result : { roleId: '', roleName: '' };
};
