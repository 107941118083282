/* istanbul ignore file */
import { gql } from '@apollo/client';

const GET_LOCAL_ERRORS = gql`
  query getLocalErrors {
    localErrors @client
  }
`;

export default GET_LOCAL_ERRORS;
