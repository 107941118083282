import { gql } from '@apollo/client';

const UPDATE_USER_PHONE = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfileV2(input: $input) {
      personalInfo {
        contactInfo {
          phones {
            category
            extension
            number
            countryCode
          }
        }
      }
    }
  }
`;

export default UPDATE_USER_PHONE;
