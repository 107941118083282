import { gql } from '@apollo/client';

const REMOVE_PROFILE_PHOTO = gql`
  mutation DeleteProfileImages($input: DeleteProfileImagesInput!) {
    deleteProfileImages(input: $input) {
      response
    }
  }
`;

export default REMOVE_PROFILE_PHOTO;
