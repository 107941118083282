// Will use different app name to be able to check data from different environment
export const getAppInfo = (environment: string) => {
  let appClientId = '';
  let appName = '';

  switch (environment) {
    case 'prd':
      appClientId = 'dxbTYYt71iuz6bonAfr8nUeeQGnlAnS1RXgjdN99HsYu56JQ';
      appName = 'profile-mfe'
      break;
    case 'stg':
      appClientId = 'kV99GJfAj9KZHtem05rN8XNQ9zCUGircilhMKYSvY4wjPX37';
      appName = 'profile-mfe-STG'
      break;
    default:
      break;  
  }

  return { appClientId, appName };
}
