import React, { ReactNode, useEffect } from 'react';
import ApolloClientProvider from '@profile-ui-mono/utilities/provider/ApolloClientProvider';
import ThemeMui from '@digital-hig/theme-mui';
import getTraceId from '../helpers/getTraceId';
import ProfileMFELayout from './ProfileMFELayout';
import ErrorBoundary from './Error/ErrorBoundary';
import TokenChecker from './common/TokenChecker';
import EnvVars from '../constants/env';
import UILogger from '../helpers/logs/LogService';
import LocaleWrapper from '../translation/LocaleWrapper';
import { Environment } from '../types/app';
import { AdpAnalyticsProvider } from '@profile-ui-mono/utilities/context';
import { getAppInfo } from '../constants';
import { performanceMetrics } from '../helpers/performanceMetrics';

const traceId = getTraceId();

interface ProfileMFEProps {
  /**
   * Locale to be passed to fetch the roles in the desired language
   */
  locale?: string;
  /**
   * Name of the client to be passed for calling the user profile service. Needs to be constructed in 2 parts, organization name and team name: eg. 'organization-team'
   */
  appId?: string;
  /**
   * Environment to be passed for callin the user profile service
   */
  env?: Environment;
  /**
   * Error to be passed to the error boundary. It takes to props title and message
   */
  errorObj?: object;
  /**
   * Skeleton component that will be shown while the data is being fetched
   */
  skeletons?: ReactNode;
  /**
   * Token to be passed to the user context
   */
  token: string;
  /**
   * Description to be shown in the header
   */
  description?: string;
  /**
   * Text to be shown in the save button
   */
  saveText?: string;
  /**
   * Text to be shown in the cancel button
   */
  cancelText?: string;
  /**
   * Text to be shown in the continue button when a user has successfully saved the data
   */
  continueText?: string;
  /**
   * Text to be shown in the success title
   */
  successTitle?: string;
  /**
   * Text to be shown in the success message
   */
  successMessage?: string;
  /**
   * Text to be shown in the link
   */
  linkText?: string;
  /**
   * Url to be passed to the link
   */
  linkUrl?: string;
  /**
   * Sets panel to be open or closed
   */
  isModalOpen?: boolean;
  /**
   * Determines if the company field is visible or not
   */
  isCompanyHidden?: boolean;
  /**
   * Custom styles for the cancel button
   */
  cancelBtnStyle?: object;
  /**
   * Custom styles for the success button
   * */
  successBtnStyle?: object;
  /**
   * Custom props that can be passed to the DHIG Modal Component
   * For more information see: https://storybook.digital-help-stg.autodesk.com/digital-hig/current/index.html?path=/docs/components-modal--api
   * */
  modalProps?: object;
  /**
   * Custom props that can be passed to the Industry Component from Smart Components
   * For more information see: http://storybook.cu.userprofile-dev.autodesk.com/?path=/story/published-industry--default
   * */
  industryProps?: object;
  /**
   * Custom props that can be passed to the Role Component from Smart Components
   * For more information see: http://storybook.cu.userprofile-dev.autodesk.com/?path=/story/published-role--default
   * */
  roleProps?: object;
  /**
   *  Custom props that can be passed to the Company Component from Smart Components
   * For more information see: http://storybook.cu.userprofile-dev.autodesk.com/?path=/story/published-company-input--company
   * */
  companyProps?: object;
  /**
   * Specifies the display variant of the component.
   *
   * 'modal' - The component will be displayed as a modal, overlaying other content
   *           with a distinct backdrop and typically providing a focused interaction.
   *
   * 'basic' - The component will be displayed in its basic form, integrated within
   *           the regular flow of content without the modal-specific features.
   *
   * If not specified, the component defaults to the 'modal' variant.
   */
  variant?: 'modal' | 'basic';
  /**
   * Callback function called when clicked save button
   * */
  onSave?: (data: any) => void;
  /**
   * Callback function called when clicked close/cancel button
   * */
  onClose?: () => void;
}

// Default props that will be used if the user does not pass any
const defaultProps: Partial<ProfileMFEProps> = {
  locale: 'en',
  env: 'dev',
  appId: 'mfe-profile-generic',
  isModalOpen: true,
  isCompanyHidden: false,
  variant: 'modal',
};

/**
 * Microfrontend React based component that retrieves and updates 'role', 'industry' and 'company' from the user profile data.
 */
const ProfileMFE: React.FC<ProfileMFEProps> = (props) => {
  const { token, errorObj, appId, onClose, locale } = props;
  const contentProps = { ...defaultProps, ...props };
  const { env } = contentProps;
  const { appClientId, appName } = getAppInfo(env);

  const { startMark, endMark, measureAndLog, clearMarks, clearMeasures } =
    performanceMetrics({ appId });
  startMark('MFE-mount-start');

  const errorBoundaryProps = {
    appId: props.appId,
    buttonText: props.cancelText,
    isModalOpen: props.isModalOpen,
    variant: props.variant,
    onClose,
    ...errorObj,
  };

  //init logger
  UILogger.init(
    {
      infoUrl: EnvVars[env].ADSK_LOG_SERVICE_INFO_URL,
      errorUrl: EnvVars[env].ADSK_LOG_SERVICE_ERROR_URL,
    },
    traceId
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      endMark('MFE-mount-end');
      measureAndLog('MFE-on-mount', 'MFE-mount-start', 'MFE-mount-end');
      clearMarks(['MFE-mount-end']);
      clearMeasures(['MFE-on-mount']);
    });
    //start marker for render
    startMark('MFE-render-start');
  }, []);

  return (
    <ErrorBoundary {...errorBoundaryProps}>
      <TokenChecker token={token} appId={appId}>
        <ApolloClientProvider
          uploadUrl={EnvVars[env].REACT_APP_USERPROFILE_UPLOAD_SVC}
          httpUrl={EnvVars[env].REACT_APP_USERPROFILE_SERVICE}
          traceId={traceId}
          connectToDevTools={env === 'dev'}
          token={token}
        >
          <AdpAnalyticsProvider
            env={env}
            productId={appClientId}
            accessToken={token}
            productName={appName}
            appId={appId}
          >
            <LocaleWrapper locale={locale} env={env}>
              <ThemeMui themePrefix="profile-widget">
                <ProfileMFELayout {...contentProps} />
              </ThemeMui>
            </LocaleWrapper>
          </AdpAnalyticsProvider>
        </ApolloClientProvider>
      </TokenChecker>
    </ErrorBoundary>
  );
};

export default ProfileMFE;
