import { DHIGTheme } from '../../theme/getThemeData';

export const avatarSizes = {
  'extra-small': {
    image: DHIGTheme['primitives.spacings.4'],
    fontSize: DHIGTheme['primitives.fontSizes.1'],
  },
  small: {
    image: DHIGTheme['primitives.spacings.6'],
    fontSize: DHIGTheme['primitives.fontSizes.1'],
  },
  medium: {
    image: DHIGTheme['primitives.spacings.7'],
    fontSize: DHIGTheme['primitives.fontSizes.2'],
  },
  'medium-large': {
    image: DHIGTheme['primitives.spacings.8'],
    fontSize: DHIGTheme['primitives.fontSizes.4'],
  },
  large: {
    image: DHIGTheme['primitives.spacings.9'],
    fontSize: DHIGTheme['primitives.fontSizes.4'],
  },
  'extra-large': {
    image: '6rem',
    fontSize: DHIGTheme['primitives.fontSizes.6'],
  },
};

export default avatarSizes;
