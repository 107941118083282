import React from 'react';
import { createTheme } from '@mui/material/styles';
import ThemeMui from '@digital-hig/theme-mui';
import GRID_BREAKPOINTS from '../constants/grid';

const theme = createTheme({
  breakpoints: {
    values: GRID_BREAKPOINTS,
  },
});

export default function ThemeProfile({ prefix, children }) {
  return (
    <ThemeMui themePrefix={prefix} theme={theme}>
      {children}
    </ThemeMui>
  );
}
