import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nConfigs from '../constants/i18nConfig';

let i18nInstance = null;

const getI18nInstance = () => {
  if (!i18nInstance) {
    i18nInstance = i18n.createInstance();
    i18nInstance.use(initReactI18next).init(I18nConfigs);
  }

  return i18nInstance;
};

export { getI18nInstance };
