import { gql } from '@apollo/client';

const UPDATE_USER_INDUSTRY = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfileV2(input: $input) {
      personalInfo {
        professionalInfo {
          additionalProfessionalInfo {
            industryTaxonomyIds {
              industryGroupId
              segmentId
              subSegmentId
            }
          }
        }
      }
    }
  }
`;

export default UPDATE_USER_INDUSTRY;
