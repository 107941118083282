import { gql } from '@apollo/client';

const UPDATE_BLOG = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfileV2(input: $input) {
      personalInfo {
        webLinks {
          blog
        }
      }
    }
  }
`;

export default UPDATE_BLOG;
