import React from 'react';
import Typography from '@digital-hig/typography';
import { withStyles } from '@material-ui/core/styles';

const StyledTypography = withStyles((theme) => ({
  root: {
    display: 'block',
    padding: '16px 0 24px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}))(Typography);

const CardDescription = ({ description, themePrefix }) => (
  <StyledTypography component="p" variant="body-copy-medium">
    {description}
  </StyledTypography>
);

export default CardDescription;
