const RETIRE_SMS_BANNER = 'ui_security_app_enable_retire_sms_banner';
const MAINTENANCE_BANNER = 'banner_info';
const MAINTENANCE_BANNER_UNSCHED = 'banner_unsched_maintenance';
const RU_LOCALE_DISABLED = 'ru_locale_disabled';
const NEW_IMG_UPLOAD_URL_ENABLED = 'new_img_upload_url_enabled';
const NEW_LANDING_PAGE = 'ui_landing_page';
const LOGOUT_PAGE_ENABLED = 'ui_logout_page_enabled';
const EXPIRED_TOKEN_LOGOUT_ENABLED = 'ui_expired_token_logout_enabled';

export const FLAG_NAMES = {
  RETIRE_SMS_BANNER,
  MAINTENANCE_BANNER,
  MAINTENANCE_BANNER_UNSCHED,
  RU_LOCALE_DISABLED,
  NEW_IMG_UPLOAD_URL_ENABLED,
  NEW_LANDING_PAGE,
  LOGOUT_PAGE_ENABLED,
  EXPIRED_TOKEN_LOGOUT_ENABLED,
};

export const FALLBACK_FLAGS = {
  [RETIRE_SMS_BANNER]: {},
  [MAINTENANCE_BANNER]: {},
  [MAINTENANCE_BANNER_UNSCHED]: true,
  [RU_LOCALE_DISABLED]: true,
  [NEW_IMG_UPLOAD_URL_ENABLED]: false,
  [NEW_LANDING_PAGE]: false,
  [LOGOUT_PAGE_ENABLED]: false,
  [EXPIRED_TOKEN_LOGOUT_ENABLED]: false,
};
