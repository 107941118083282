import React, { useState, useEffect } from 'react';
import { sendLog } from '@profile-ui/shared-components';
import { FALLBACK_FLAGS } from '@profile-ui-mono/utilities/constants';
import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk';

const ErrorBoundary = ({ children }) => {
  const ldClient = useLDClient();

  useEffect(() => {
    ldClient.on('error', (e) => {
      sendLog(e, 'LaunchDarklyProvider', 'LD SDK Error');
    });
  }, []);

  return children;
};

const LaunchDarklyProvider = ({ children, skeletons = null }) => {

  const [loading, setLoading] = useState(true);
  const [ProviderComponent, setProviderComponent] = useState(null);

  useEffect(() => {

    const initLDProvider = async () => {
      
      const Component = await asyncWithLDProvider({
        clientSideID: window.$profileEnvVars.LAUNCH_DARKLY_CLIENT_SIDE_ID,
        context: { kind: 'user', key: 'profile' },
        reactOptions: { useCamelCaseFlagKeys: false },
        options: { bootstrap: 'localStorage',streaming: false },
        
        /*
          It seems like the flags prop only helps in filtering flags. It's unclear what kind of fallback functionality this offers.
          These don't appear to work as a fallback when there is a network error, nor does putting these in useFlags(fallback).
        */
        flags: FALLBACK_FLAGS,
      });

      setProviderComponent(() => Component);
      setLoading(false);
    };
 
    initLDProvider();
  }, []);

  if (loading) return skeletons;
 
  return (
    <ProviderComponent>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </ProviderComponent>
  );
};

export default LaunchDarklyProvider;
