import React, { useState } from 'react';
import SvgIcon from '@digital-hig/svg-icon';
import SvgInfoOutline from '@digital-hig/icon/lib/build/icons/utility/info-outline';

import ThemeMui from '@digital-hig/theme-mui';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { DHIGTheme } from '../../theme/getThemeData';

const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip a,
  .MuiTooltip-tooltip a:is(:visited, :hover, :active) {
    color: ${DHIGTheme['primitives.colors.primary.white']};
    text-decoration: underline;
    text-decoration-color: ${DHIGTheme['primitives.colors.primary.white']};
  }

  & .MuiTooltip-tooltip p {
    margin: 0px;
  }
`;

const CardTooltip = ({ description }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ThemeMui>
      <StyledTooltip
        title={<p>{description}</p>}
        arrow
        placement="bottom"
        className="info-tooltip"
        disableHoverListener
        disableTouchListener
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        slotProps={{
          popper: { sx: { maxWidth: '240px' } },
          tooltip: { sx: { mt: '8px !important', mb: '8px !important' } },
        }}
      >
        <sup
          className="info__icon"
          role="button"
          tabIndex={0}
          onClick={() => setIsOpen(!isOpen)}
        >
          <SvgIcon
            size="medium"
            title="card tooltip icon"
            data-testid="icon-card-tooltip"
          >
            <SvgInfoOutline />
          </SvgIcon>
        </sup>
      </StyledTooltip>
    </ThemeMui>
  );
};

export default CardTooltip;
