// Helper methods for user provider.
import jwt_decode from 'jwt-decode';
import { sendLog } from '@profile-ui/shared-components';

export const getUserIdFromToken = (accessToken) => {
  try {
    const decodedToken = jwt_decode(accessToken);
    if (decodedToken?.userid) {
      return decodedToken.userid;
    }
  } catch (err) {
    //TODO log error & display error screen
    sendLog(
      `Error parsing access token, ${accessToken}`,
      err,
      'getUserIdFromToken'
    );
    return null;
  }
};
