import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import Button from '@digital-hig/button';
import ThemeMui from '@digital-hig/theme-mui';
import Typography from '@digital-hig/typography';
import Modal from '@digital-hig/modal';
import { maxWidths } from '@digital-hig/modal/lib/constants';
import Box from '@mui/material/Box';

const MODAL_CANCEL_PADDING = 32;
const MODAL_CANCEL_WIDTH = 342 - MODAL_CANCEL_PADDING * 2; // 342px https://www.figma.com/file/CJaBHuba0rWWyfzyNwM5Do/Profile-%7C-Basic-2022---VisD?type=design&node-id=196%3A271029&mode=design&t=iaWx5SB6vtpqbEsw-1

const ModalCancel = ({
  open,
  onSave,
  onCancel,
  onClose = onCancel,
  lang = {
    TITLE: 'Do you want to save changes?',
    CANCEL: 'Cancel',
    SAVE: 'Save',
  },
  isSaveBtnDisabled,
  portalContainerRef,
}) => {
  const { TITLE, CANCEL, SAVE } = lang;
  const buttonsRef = useRef();

  useEffect(() => {
    if (buttonsRef.current && buttonsRef.current.children.length) {
      buttonsRef.current.children[0].focus();
    }
  });

  const UnwrappedModal = () => (
    <ThemeMui themePrefix="modal">
      <Modal
        backdropVariant="default"
        closeButtonDisplay
        maxWidth={maxWidths.MD}
        styleVariant="default"
        type="modal"
        open={open}
        handleClose={onClose}
        content={
          <Box sx={{ minWidth: { sm: 'none', md: MODAL_CANCEL_WIDTH } }}>
            <Typography variant="body-copy-medium">{TITLE}</Typography>
          </Box>
        }
        actions={
          <>
            <Button
              layout="stretch"
              size="default"
              variant="outlined"
              data-testid="modal-cancel-btn_cancel"
              onClick={onCancel}
            >
              {CANCEL}
            </Button>

            <Button
              layout="stretch"
              size="default"
              variant="contained"
              data-testid="modal-cancel-btn_save"
              onClick={onSave}
              disabled={isSaveBtnDisabled}
            >
              {SAVE}
            </Button>
          </>
        }
      />
    </ThemeMui>
  );

  // Use a Portal if the container ref prop is present
  if (portalContainerRef?.current) {
    return createPortal(<UnwrappedModal />, portalContainerRef.current);
  }
  return <UnwrappedModal />;
};

ModalCancel.defaultProps = {
  open: false,
  onSave: () => {},
  onCancel: () => {},
  onClose: () => {},
  lang: {
    TITLE: 'Do you want to save changes?',
    CANCEL: 'Cancel',
    SAVE: 'Save',
  },
  isSaveBtnDisabled: false,
  portalContainerRef: null,
};

ModalCancel.propTypes = {
  open: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  lang: PropTypes.oneOfType([PropTypes.object]),
  isSaveBtnDisabled: PropTypes.bool,
  portalContainerRef: PropTypes.oneOfType([PropTypes.object]),
};

export default ModalCancel;
