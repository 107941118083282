import { gql } from '@apollo/client';

const GET_SETTINGS = gql`
  query UserSettings($filter: UserSettingsInput!) {
    userSettings(filter: $filter) {
      personalInfo {
        primaryEmail
        language
        contactInfo {
          alternateEmail
        }
      }
      preferences {
        # Communications 
        receivePhoneComms
        receiveEmail
        receiveProductLearningContent
        receiveNewsTrendsOffers
        receiveShareYourThoughts
        receiveTrialLearningContent
        
        # Newsletters
        receiveFoundationNewsletter
        receiveAreaNewsletter
        receiveUniversityNewsletter
        receiveRedShiftNewsletter
        receiveFusion360BlogNewsletter
        receiveForgeNewsletter
        receiveAutodeskNewsletter
        receiveAutodeskGalleryNewsletter
        receiveShotGunRoadMapNewsletter
        receiveBIM360Newsletter
        receiveAECNewsletter
        receiveSpacemakerNewsletter
        receiveAutodeskResearchCommunityNewsletter
        receiveDesignStudioNewsletter
        receivePDMCommunityNewsletter
        receiveAECXRNewsletter
        receiveTechAdminNewsletter

        # Product Privacy
        receivePUDNecessary
        receivePUDOptimization
        receivePUDGoToMarket
      }
    }
  }
`;

export default GET_SETTINGS;
