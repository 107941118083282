// view port size
const GRID_BREAKPOINTS = {
  xs: 0,
  sm: 320,
  smm: 480,
  md: 576,
  lg: 992,
  mxl: 1390,
  xl: 2048,
};
export default GRID_BREAKPOINTS;
