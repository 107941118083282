import React, { Component } from 'react';

const AppStateContext = React.createContext();

class AppStateProvider extends Component {
  state = {
    sidePanelOpened: false,
    leftNavOpened: false,
    selectedPanel: '',
    modalOpened: false,
    isInputValueChanged: false,
    modalContainerRef: null,
  };

  setInputValueChanged = (bool) => {
    this.setState({ isInputValueChanged: bool });
  };

  setSelectedPanel = (selectedPanel) => {
    this.setState({ selectedPanel });
  };

  togglePanelOpen = () => {
    this.setState({ sidePanelOpened: !this.state.sidePanelOpened });
  };

  closePanel = () => {
    this.setState({ sidePanelOpened: false, selectedPanel: '' });
  };

  openPanel = () => {
    this.setState({ sidePanelOpened: true });
  };

  closeLeftNav = () => {
    this.setState({ leftNavOpened: false });
  };

  openLeftNav = () => {
    this.setState({ leftNavOpened: true });
  };

  openModal = () => {
    this.setState({ modalOpened: true });
  };

  closeModal = () => {
    this.setState({ modalOpened: false });
  };

  toggleLeftNav = () => {
    this.setState({ leftNavOpened: !this.state.leftNavOpened });
  };

  setModalContainerRef = (ref) => {
    this.setState({ modalContainerRef: ref });
  };

  render() {
    return (
      <AppStateContext.Provider
        value={{
          sidePanel: {
            opened: this.state.sidePanelOpened,
            toggle: this.togglePanelOpen,
            close: this.closePanel,
            open: this.openPanel,
            selectedPanel: this.state.selectedPanel,
            setSelectedPanel: this.setSelectedPanel,
          },
          leftNav: {
            opened: this.state.leftNavOpened,
            close: this.closeLeftNav,
            open: this.openLeftNav,
            toggle: this.toggleLeftNav,
          },
          modal: {
            opened: this.state.modalOpened,
            close: this.closeModal,
            open: this.openModal,
            isInputValueChanged: this.state.isInputValueChanged,
            setInputValueChanged: this.setInputValueChanged,
            containerRef: this.state.modalContainerRef,
            setContainerRef: this.setModalContainerRef,
          },
        }}
      >
        {this.props.children}
      </AppStateContext.Provider>
    );
  }
}

export { AppStateContext, AppStateProvider };
