import { gql } from '@apollo/client';

const UPDATE_PROFILE_PHOTO = gql`
  mutation UpdateProfileImage($input: UpdateProfileImageInput!) {
    updateProfileImageV2(input: $input) {
      isImagePresent
      thumbnails {
        sizeX120
      } 
    }
  }
`;

export default UPDATE_PROFILE_PHOTO;
