/** Simple data helper to parse the userprofile data from the query and expose independent fields.
  * This is used to avoid having to parse the query data in multiple places. Should match the userprofile query contract
  * Designed not to store any data intentionally to avoid stale data issues esp with Apollo Cache
  
  * @param {object} data - the data returned from the userprofile query
  * @returns {object} - an object with the fields exposed
  * @example
  * const { firstName, lastName, avatar } = DataHelper(data)
  
*/
// TODO: Consider this function helper to be a hook instead.
function DataHelper(data) {
  const { personalInfo, profileImages } = data?.userProfile;
  const { aboutMe, professionalInfo, basicInfo, webLinks, contactInfo } = personalInfo;

  return {
    personalInfo,
    profileImages,
    firstName: basicInfo?.firstName,
    lastName: basicInfo?.lastName,
    avatar: profileImages?.thumbnails?.sizeX120,
    aboutMe: aboutMe,
    primaryEmail: personalInfo?.primaryEmail,
    countryCode: personalInfo?.countryCode,
    language: personalInfo?.language,
    isLdapEnabled: personalInfo?.ldapInfo?.isLdapEnabled,
    industry: professionalInfo?.industry,
    industryTaxonomyIds:
      professionalInfo?.additionalProfessionalInfo?.industryTaxonomyIds ?? [],
    industryCode: professionalInfo?.industryCode,
    jobTitle: professionalInfo?.jobTitle,
    roleId: professionalInfo?.additionalProfessionalInfo?.roleId,
    roleName: data?.roleName,
    companyName: professionalInfo?.company?.companyName,
    address: contactInfo?.address,
    phones: contactInfo?.phones,
    alternateEmail: contactInfo?.alternateEmail,
    blog: webLinks?.blog,
    linkedInProfile: webLinks?.linkedInProfile,
    website: webLinks?.website,
  };
}

export default DataHelper;
