import UILogger from './LogService';

/**
 * Frame log in the format needs to be sent to dynatrace
 * @param {object} error
 * @param {string} errorInfo
 */
const frameLog = (error, errorInfo) => ({
  message: error?.message || 'Custom Error',
  info: errorInfo || 'No Stack trace attached',
});

/**
 * Frame error log in the format needs to be sent to Log service
 * @param {object} error
 * @param {string} errorInfo -> hints
 */
const frameErrorDetails = (error, errorInfo) => {
  const errorDetails = {
    errorDetails: [
      {
        message: error?.message,
        stacktrace: errorInfo,
      },
    ],
  };
  return errorDetails;
};

/**
 * Report Custom Error to dynatrace
 * @param {Error} error
 * @param {string} errorInfo
 * @param {string} context
 */
const sendLogsToDynatrace = (error, context, errorInfo) => {
  if (window.dtrum?.reportCustomError) {
    const err = frameLog(error, errorInfo);
    window.dtrum.reportCustomError(context, err.message, err.info);
  }
};

/**
 * Report Error to Splunk
 * @param {Error} error
 * @param {string} errorInfo
 * @param {string} context
 */
const sendLog = (error, errorInfo, context) => {
  // send errors to log service
  const logInstance = UILogger.getInstance();
  if (logInstance) {
    const errDetails = frameErrorDetails(error, errorInfo);
    logInstance.sendErrorLog({ context, msg: errDetails });
  } else {
    // send it to DT
    sendLogsToDynatrace(error, context, errorInfo);
  }
};

/**
 * Report custom logs to splunk
 * @param {string} msg
 * @param {string} context
 */
const sendCustomLog = (context, msg) => {
  // send logs to log service
  const logInstance = UILogger.getInstance();

  if (logInstance) {
    logInstance.sendInfoLog({ context, msg });
  } else {
    // send it to DT
    sendLogsToDynatrace({ message: msg }, context);
  }
};

export { sendLog, sendCustomLog };
