//TODO: Temporiarly copied from Accounts portal, Will be replaced by CFP Analytics in future
import React from 'react';
import PropTypes from 'prop-types';

const EVENT_TYPES = {
  LINK_CLICK: 'link_click',
  CONTENT_VIEWED: 'contentViewed',
};

const TealiumTags = ({
  tags,
  link,
  linkName,
  linkSection,
  val,
  location,
  contentType,
  contentName,
  children,
}) => {
  const dataAttrs = {
    'data-wat-link': link, // used for click events
    'data-wat-linkname': linkName, // used for click events
    'data-wat-link-section': linkSection, // used for click events
    'data-wat-val': val, // used for click events
    'data-wat-loc': location, // used for content location
    'data-wat-content-name': contentName, // used for content presence events
    'data-wat-content-type': contentType, // used for content presence events
  };
  // remove undefined attributes
  const dataAttributes = JSON.parse(JSON.stringify(dataAttrs));

  Object.keys(tags).forEach((key) => {
    const keyAttr = key.toLowerCase().replace(/data-wat-/gi, '');
    dataAttributes[`data-wat-${keyAttr}`] = tags[key];
  });

  if (React.Children.count(children) > 1) {
    // adds a single parent level element to add the data attributes
    return React.cloneElement(<span />, dataAttributes, children);
  }
  return React.cloneElement(children, dataAttributes);
};

TealiumTags.addEvent = (eventType, eventData = {}) => {
  const eventObject = {
    event: {
      eventType,
    },
    ...eventData,
  };

  try {
    window.__analyticsChangeContext = window.__analyticsChangeContext || [];
    window.__analyticsChangeContext.push(eventObject);

    /*
      PROFILE-3797 clickType gets added when an eventObject is added (above).
      For now, we will delete it manually since it will linger for subsequent events.
      TODO We are working with the analytics team to fix this in the Tealium script instead.
    */
    delete window.digitalData.clickType;
  } catch (e) {
    console.warn(e);
  }
};

TealiumTags.addLinkClickEvent = ({
  linkSection = '',
  linkTitle = '',
  linkDestinationUrl = '',
  contentContainer = {},
} = {}) => {
  const eventObject = {
    link: {
      linkSection,
      linkTitle,
      linkDestinationUrl,
    },
    contentContainer,
  };
  TealiumTags.addEvent(EVENT_TYPES.LINK_CLICK, {
    ...eventObject,
  });
};

TealiumTags.addContentViewedEvent = (contentContainer = {}) => {
  TealiumTags.addEvent(EVENT_TYPES.CONTENT_VIEWED, {
    contentContainer,
  });
};

// moved over directly from ui-toolkit

TealiumTags.formatText = (text) => text.toLowerCase().replace(/\s/g, '-');

TealiumTags.sendAnalytics = ({
  title = '',
  location = '',
  description = '',
  linkSection = 'content',
  linkTitle,
}) => {
  try {
    const separator = description ? ':' : '';
    const descriptionText = TealiumTags.formatText(description);
    const locationText = TealiumTags.formatText(location);
    const titleText = TealiumTags.formatText(title);
    window.analytics.callback.linkClick(
      linkTitle || `${locationText}-${titleText}${separator}${descriptionText}`,
      linkSection
    );
  } catch (e) {
    console.warn(e);
  }
};

TealiumTags.propTypes = {
  tags: PropTypes.objectOf(PropTypes.string),
};

TealiumTags.defaultProps = {
  tags: {},
};

export default TealiumTags;
