import { useState, useEffect } from 'react';

const useAccessToken = () => {
  const auth = window?.cfp?.providers?.auth;

  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    if (!auth) return;
    auth.getAccessToken().then(setAccessToken);

    // TODO handle/log errors
  }, []);

  return accessToken;
};

export default useAccessToken;
