import React, { useState, useContext, createContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { LocaleContext, DataContext } from '@profile-ui-mono/utilities/context';
import { GET_LOCALE_BASED_OPTIONS } from '@profile-ui-mono/utilities/queries';
import {
  localeSesMap,
  getBrowserLocale,
  DataHelper,
  getSelectedRole,
} from '@profile-ui-mono/utilities/helpers';

const ExtraProfileDataContext = createContext();

const ExtraProfileDataProvider = ({ children }) => {
  const { locale } = useContext(LocaleContext);
  const { profileData } = useContext(DataContext);
  const { roleId, jobTitle } = DataHelper(profileData);
  const { defaultLng } = getBrowserLocale();
  const [roleName, setRoleName] = useState('');

  const langFromSesMap = localeSesMap[locale || defaultLng] || locale || defaultLng;

  const { loading, error, data } = useQuery(
    GET_LOCALE_BASED_OPTIONS,
    {
      variables: {
        rolesFilter: { lang: langFromSesMap },
        subSegmentsFilter: { lang: langFromSesMap },
      } 
    },
    { fetchPolicy: 'cache-first' }
  );

  const subSegments = data?.subSegments?.subSegments ?? [];

  useEffect(() => {
    if (data && data?.roles?.roles && profileData) {
      const rolesTemp = data.roles.roles;
      const roleObj = getSelectedRole(rolesTemp, roleId, jobTitle);
      const roleNameTemp = rolesTemp.find(
        (obj) => obj.roleId === roleObj.roleId
      )?.roleName;

      setRoleName(roleNameTemp);
    }
  }, [locale, data, profileData]);

  if (error) {
    const msgPrefix = 'Userprofile service';
    const message =
      error && error.message !== 'undefined'
        ? `${msgPrefix} : ${error.message}`
        : `${msgPrefix} : Unknown error`;

    throw new Error(message);
  }

  return (
    <ExtraProfileDataContext.Provider value={{ roleName, subSegments, loading }}>
      {children}
    </ExtraProfileDataContext.Provider>
  );
};

export { ExtraProfileDataContext, ExtraProfileDataProvider };
