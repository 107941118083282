import React, { useState, createContext } from 'react';

const UniversalFooterContext = createContext();

/**
 * React Context used to store the reference of the element that will eventually contain the Universal Footer.
 */
const UniversalFooterProvider = ({ children }) => {
  // the reference of the UF container element is set at shared-components Container component.
  // we then read that reference inside the useUniversalFooter hook to ensure that the Universal Footer container is ready inside the DOM.
  const [universalFooterRef, setUniversalFooterRef] = useState(null);

  return (
    <UniversalFooterContext.Provider
      value={[universalFooterRef, setUniversalFooterRef]}
    >
      {children}
    </UniversalFooterContext.Provider>
  );
};

export { UniversalFooterContext, UniversalFooterProvider };
