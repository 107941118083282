import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MUIAvatar from '@material-ui/core/Avatar';
import { DHIGTheme } from '../../theme/getThemeData';
import getInitialsFromName from '../../helpers/common/getInitialsFromName';
import avatarSizes from '../../helpers/common/getAvatarSizes';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: 0,
  },

  avatar: {
    backgroundColor: DHIGTheme['primitives.colors.accent.iris'],
    color: DHIGTheme['primitives.colors.primary.white'],
    fontFamily: DHIGTheme['primitives.fontFamilies.default'],
    height: (props) => avatarSizes[props.size].image,
    width: (props) => avatarSizes[props.size].image,
    fontSize: (props) => avatarSizes[props.size].fontSize,
  },
  caption: {
    fontSize: DHIGTheme['primitives.fontSizes.1'],
    marginTop: DHIGTheme['primitives.spacings.1'],
  },
}));

const Avatar = ({
  imgSrc,
  name,
  caption,
  customStyles,
  size,
  className,
  ...otherprops
}) => {
  const classes = useStyles({ size });

  return (
    <figure className={classes.root}>
      <MUIAvatar
        className={`${classes.avatar} ${className}`}
        src={imgSrc}
        style={customStyles}
        {...otherprops}
      >
        {getInitialsFromName(name)}
      </MUIAvatar>
      {caption && (
        <figcaption className={classes.caption}>{caption}</figcaption>
      )}
    </figure>
  );
};

Avatar.defaultProps = {
  imgSrc: null,
  name: 'Anonymous User',
  size: 'large',
  caption: null,
  customStyles: null,
  className: '',
  otherprops: null,
};

Avatar.propTypes = {
  /**
   * Image src to be displayed inside Avatar.
   */
  imgSrc: PropTypes.string,
  /**
   * Fullname of the user.
   */
  name: PropTypes.string,
  /**
   * Choose from 'extra-small (16px)' 'small  (24px)', 'medium' (32px), 'large (64px)' or 'extra-large (96px)'
   */
  size: PropTypes.oneOf([
    'extra-small',
    'small',
    'medium',
    'medium-large',
    'large',
    'extra-large',
  ]),
  /**
   * Text to be displayed below the Avatar.
   */
  caption: PropTypes.string,
  /**
   * Custom styles to be added to the Avatar.
   */
  customStyles: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  /**
   * classes to be added to the Avatar.
   */
  className: PropTypes.string,
  /**
   * Material UI Props that can be passed directly to MUI's Avatar.
   */
  otherprops: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default Avatar;
