import { gql } from '@apollo/client';

const UPDATE_LINKEDIN = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfileV2(input: $input) {
      personalInfo {
        webLinks {
          linkedInProfile
        }
      }
    }
  }
`;

export default UPDATE_LINKEDIN;
