import { gql } from '@apollo/client';

const UPDATE_USER_COMPANY = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfileV2(input: $input) {
      personalInfo {
        professionalInfo {
          company {
            companyName
          }
        }
      }
    }
  }
`;

export default UPDATE_USER_COMPANY;
