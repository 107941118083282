import { gql } from '@apollo/client';

const UPDATE_USER_NAME = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfileV2(input: $input) {
      personalInfo {
        basicInfo {
          firstName
          lastName
        }
      }
    }
  }
`;

export default UPDATE_USER_NAME;
