// locale for Universal Header and Universal Footer
// UH locales https://wiki.autodesk.com/display/EFDE/Universal+Header+-+Integration
// UF locales https://wiki.autodesk.com/display/EFDE/Universal+Footer+-+Integration#UniversalFooterIntegration-SupportedLocales
const oxygenToHeaderMap = {
  en: 'en-US',
  cs: 'cs-CZ',
  da: 'da-DK',
  de: 'de-DE',
  es: 'es-ES',
  fi: 'fi-FI',
  fr: 'fr-FR',
  fr_CA: 'fr-CA',
  hu: 'hu-HU',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  nb: 'no-NO',
  nl: 'nl-NL',
  pl: 'pl-PL',
  pt_BR: 'pt-BR',
  pt_PT: 'pt-PT',
  ru: 'ru-RU',
  sv: 'sv-SE',
  tr: 'tr-TR',
  zh_CN: 'zh-CN',
  zh_HK: 'zh-TW',
  zh_TW: 'zh-TW',
};

const getLocale = locale => oxygenToHeaderMap[locale] || 'en-US';

export default getLocale;