import React, { useState, useEffect } from 'react';
import { getUserIdFromToken } from '@profile-ui-mono/utilities/helpers/userHelper';
import { sendCustomLog, useAccessToken } from '@profile-ui/shared-components';

const UserContext = React.createContext();

const UserProvider = ({ children, skeletons = null }) => {
  const [userId, setUserId] = useState(null);
  const accessToken = useAccessToken();

  useEffect(() => {
    if (accessToken?.length) {
      const id = getUserIdFromToken(accessToken);
      sendCustomLog('UserProvider', `Session loaded for user, ${id}`);
      setUserId(id);
    }
  }, [accessToken]);

  return userId ? (
    <UserContext.Provider value={userId}>{children}</UserContext.Provider>
  ) : (
    skeletons
  );
};

export { UserContext, UserProvider };
