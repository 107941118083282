/* eslint-disable class-methods-use-this */
import axios from 'axios';

let logInstance = null;

const getHeaders = (traceId) => {
  return {
    headers: {
      'x-amzn-trace-id': traceId,
      Authorization: '123e4567-e89b-12d3-a456',
    },
  };
};
class LogService {
  constructor(urls, traceId) {
    this.infoUrl = urls.infoUrl;
    this.errorUrl = urls.errorUrl;
    this.traceId = traceId;
  }

  setTraceId = (traceId) => {
    this.traceId = traceId;
  };

  sendInfoLog = async (data) => {
    try {
      await axios.post(this.infoUrl, data, getHeaders(this.traceId));
    } catch (err) {
      throw new Error('Logservice failed to sendInfoLog', err);
    }
  };

  sendErrorLog = async (data) => {
    try {
      await axios.post(this.errorUrl, data, getHeaders(this.traceId));
    } catch (err) {
      throw new Error('Logservice failed to sendErrorLog');
    }
  };
}

export default {
  init: (urls, traceId) => {
    if (!logInstance) {
      logInstance = new LogService(urls, traceId);
    }

    return logInstance;
  },

  getInstance: () => {
    return logInstance;
  },
};
