import React, { useState, useEffect, createContext } from 'react';
import {
  transformAEMData,
  getLocale,
  sendCustomLog,
  sendLog,
} from '@profile-ui/shared-components';
import { getI18nInstance } from '../helpers/i18nHelper';
import { processContentFallback } from '../helpers';

const LocaleContext = createContext();

export const MAXIMUM_NUMBER_OF_ENTITIES = 102; // update this number to cache burst aem endpoint.

const LocaleProvider = ({ children, lang, skeletons = null }) => {
  const i18n = getI18nInstance();
  const [locale, setLocale] = useState(lang);
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    (async () => {
      const isoLocale = getLocale(locale);
      sendCustomLog('LocaleProvider', `Fetching content for locale, ${isoLocale}`);

      let rawAEMData;
      try {
        rawAEMData = await window.cfp.providers.content.get('userprofile', {
          locale: isoLocale,
          limit: MAXIMUM_NUMBER_OF_ENTITIES,
        });
        sendCustomLog(
          'LocaleProvider',
          `Successfully fetched content from AEM for locale, ${isoLocale}`
        );
      } catch (error) {
        rawAEMData = await processContentFallback(isoLocale);
      }

      const aemContents = transformAEMData(rawAEMData);

      if (Object.keys(aemContents).length) {
        for (let ns in aemContents) {
          i18n.addResourceBundle(isoLocale, ns, aemContents[ns]);
        }

        i18n.changeLanguage(isoLocale);
      }

      setIsDataReady(true);
    })();
  }, [locale, isDataReady]);

  return !locale || !isDataReady ? (
    skeletons
  ) : (
    <LocaleContext.Provider
      value={{
        locale,
        setLocale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export { LocaleContext, LocaleProvider };
