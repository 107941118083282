import { localErrorsField } from '../helpers/error/apolloLocalErrors';

export default {
  Query: {
    fields: {
      ...localErrorsField,
    },
  },

  /**
   * PROFILE-2881 C&P Newsletter Names Integration
   * The shape of programGroup.locales[0].displayTags is the same for both programGroups.
   * This type policy caches them with a composite cache id based on displayTags and value to
   * prevent apollo client from merging the displayTags from the two queries.
   */
  Locales: {
    keyFields: ['displayTags', ['value']],
  },

  /**
   * The data for programGroups and programs have similar shapes.
   * Each programGroup and program has multiple entries in the locales array.
   * The id for each entry is in the array which prevents Apollo cache from
   * differentiating them.
   * These type policies give each programGroup and program composite cache ids so they
   * can be correctly cached individually.
   */
  ProgramGroups: {
    keyFields: ['id', 'locales', ['id']],
  },
  Programs: {
    keyFields: ['id', 'locales', ['id']],
  },

};
