import React from 'react';
import Typography from '@digital-hig/typography';
import { withStyles } from '@material-ui/core/styles';
import CardTooltip from './CardTooltip';

const StyledTypography = withStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingBottom: '24px',
    fontFamily: 'ArtifaktLegend, sans-serif',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: '0',
      overflowWrap: 'anywhere',
    },
  },
}))(Typography);

const CardTitle = ({ title, description }) => {
  return (
    <StyledTypography component="h2" variant="headline-medium">
      {title}
      <CardTooltip description={description} />
    </StyledTypography>
  );
};

export default CardTitle;
