import {
  sendCustomLog,
  sendLog,
} from '@profile-ui/shared-components';

const processContentFallback = async (isoLocale) => {
  let rawAEMData;
  try {
    const response = await fetch(`${window.location.origin}/static/localeContent.json`);
    rawAEMData = await response.json();
    sendCustomLog(
      'LocaleFallback',
      `Successfully fetched content from fallback for locale, ${isoLocale}`
    );
  } catch (error) {
    sendLog(
      `Error while fetching content from fallback for locale, ${isoLocale}`,
      '',
      'LocaleFallback'
    );
  }

  return rawAEMData;
}

export default processContentFallback;
