import { sendCustomLog } from '@profile-ui/shared-components';

//TODO: Use oxygenToHeaderMap from getLocale.js after shared components cleanup
const validLocales = new Set([
  'en',
  'cs',
  'da',
  'de',
  'es',
  'fi',
  'fr',
  'fr_CA',
  'hu',
  'it',
  'ja',
  'ko',
  'nb',
  'nl',
  'pl',
  'pt_BR',
  'pt_PT',
  'ru',
  'sv',
  'tr',
  'zh_CN',
  'zh_HK',
  'zh_TW',
]);

const treatLocale = (locale) => {
  if (validLocales.has(locale)) {
    return locale;
  }

  sendCustomLog(
    'utilities/helpers/treatLocale',
    `invalid locale used: ${locale}, using en as fallback`
  );

  return 'en';
};

export default treatLocale;
