import { gql } from '@apollo/client';

const UPDATE_PRIVACY = gql`
  mutation UpdateUserPreferences($input: UpdateUserPreferencesInput!) {
    updateUserPreferencesV2(input: $input) {
      preferences {
        receivePUDOptimization
        receivePUDGoToMarket
      }
    }
  }
`;

export default UPDATE_PRIVACY;
