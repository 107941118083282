import { gql } from '@apollo/client';

const UPDATE_ADDRESS = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfileV2(input: $input) {
      personalInfo {
        contactInfo {
          address {
            addressLine1
            addressLine2
            city
            stateProvince
            postalCode
            country
          }
        }
      }
    }
  }
`;

export default UPDATE_ADDRESS;
