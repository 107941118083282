/*
  Tealium script injection
  This script can be loaded after the page load and is used for page click events
*/
import React from 'react';

const createScript = () => {
  const tealiumScript = document.createElement('script');
  tealiumScript.setAttribute(
    'src',
    window.$profileEnvVars.ADSK_ACCOUNT_TEALIUM_SCRIPT
  );
  tealiumScript.setAttribute('type', 'text/javascript');
  tealiumScript.setAttribute('async', true);

  return tealiumScript;
};

class TealiumScript extends React.Component {
  /* eslint-disable no-useless-constructor */
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const tealiumScript = createScript();
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(tealiumScript, firstScript);
  }

  render() {
    return null;
  }
}

export default TealiumScript;
