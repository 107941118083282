import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Adp } from '@adsk/adp-web-analytics-sdk';

const AdpAnalyticsContext = createContext();

export const AdpAnalyticsProvider = ({
  env,
  productId,
  productName,
  accessToken,
  appId,
  children,
}) => {
  const [isReadyToTrack, setIsReadyToTrack] = useState(false);

  const adp = useMemo(() => {
    // Skip DEV env since we will not be able to verify events
    if (env === 'dev') {
      console.log('Dev would be skipped!');
      return;
    }

    // Get adp instance
    const adpEnv = env === 'prd' ? 'prd' : 'stg';
    const adpClient = Adp().setEnv(adpEnv);
    if (adpEnv !== 'prd') {
      adpClient.enableDebug();
    }

    /*
     ** Load & setup the Web SDK
     ** https://pages.git.autodesk.com/cloudplatform-bigdata/adp-web-analytics-sdk/classes/AdpAnalytics.html#load
     ** productId => app client id
     */
    const productInfo = {
      id: productId,
      id_provider: 'appkey',
      name: productName, 
    };

    adpClient.load(productInfo);

    return adpClient;
  }, [env, productId, productName]);

  useEffect(() => {
    if (!adp) return;

    const loadUserConsent = async () => {
      try {
        const result = await adp.loadUserConsentPreferences(accessToken);
        if (result.status.code === result.status.PENDING_USER_ACTION) {
          adp.showUserConsentDialog();
        }
    
        adp.identify();
        adp.enableAutoCaptureClickEvents();
        // Connecting parent app with MFE. Parent app should call setHostProduct() method 
        adp.enableHostProductData(appId);
        setIsReadyToTrack(true);
      } catch (error) {
        // TODO: we should send some logs to Splunk in the future 
        console.log(`${error}: User Consent preferences was not loaded!`); 
      }
    }

    loadUserConsent();
  }, [adp, accessToken]);

  return (
    <AdpAnalyticsContext.Provider value={isReadyToTrack ? adp : null}>
      {children}
    </AdpAnalyticsContext.Provider>
  );
};

// Custom hook
export const useAdpAnalytics = () => {
  const context = useContext(AdpAnalyticsContext);
  return context;
};
