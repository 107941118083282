// localeSesMap for locales which needs mapping
const localeSesMap = {
  fr_CA: 'fr-ca',
  nb: 'no',
  pt_BR: 'pt',
  pt_PT: 'pi',
  zh_CN: 'zh',
  zh_TW: 'cv',
  zh_HK: 'yue-hk',
};

export default localeSesMap;
